import React, { useState, useEffect, useRef } from 'react';
import '../css/Popup.css';

function Popup({
  isOpen,
  togglePopup,
  handleAcceptTerms,
  handleViewRegulations,
}) {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // New state
  const termsRef = useRef(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Define state for button enabled

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleAcceptTermsClick = () => {
    handleAcceptTerms();
    window.open('https://circ.eventqualia.net/pt/inicio/', '_blank'); // Open a new window
  };

  useEffect(() => {
    const handleScroll = () => {
      if (termsRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          // Verifica se chegou ao fim
          setIsButtonEnabled(true); // Enable the button
        }
      }
    };

    if (termsRef.current) {
      termsRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (termsRef.current) {
        termsRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    isOpen && (
      <div className="popup">
        <div className="popup-content">
          <span className="close" onClick={togglePopup}>
            &times;
          </span>
          {!acceptedTerms ? (
            <div>
              <div
                className="terms-container"
                ref={termsRef}
                style={{
                  textAlign: 'justify',
                  fontFamily: 'Arial, sans-serif',
                  lineHeight: '1.6',
                }}
              >
                <p>
                  Ao se inscrever, terá direito a estacionamento gratuito,
                  coffee break, almoços, documentação e uma visita à Medical
                  Radiology Exhibition.
                </p>
                <label>
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                  Li e aceito as condições do regulamento
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                  gap: '20px',
                }}
              >
                <button
                  onClick={handleViewRegulations}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = '#0056b3')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = '#007bff')
                  }
                >
                  Ver Regulamento Completo
                </button>
                <button
                  onClick={handleAcceptTermsClick} // Use the new function
                  style={{
                    cursor: isCheckboxChecked ? 'pointer' : 'not-allowed', // Conditionally enable the button
                    backgroundColor: isCheckboxChecked ? '#007bff' : '#ccc', // Conditionally change color
                    color: '#fff',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseEnter={(e) => {
                    if (isCheckboxChecked) {
                      e.target.style.backgroundColor = '#0056b3';
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (isCheckboxChecked) {
                      e.target.style.backgroundColor = '#007bff';
                    }
                  }}
                  disabled={!isCheckboxChecked} // Disable the button
                >
                  Aceitar
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h2>Obrigado por aceitar os termos!</h2>
              <p>Você será redirecionado para a página de convite.</p>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default Popup;
